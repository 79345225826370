import React from 'react'
import HeaderWave from "./images/headerwave.png";
import FullLogo from "./images/FullLogo.png";

function Header() {
  return (
    <div className="absolute top-0 left-0 right-0 flex text-white z-10">
        <img
          className="absolute w-full sm:h-auto h-40"
          src={HeaderWave}
          draggable={false}
          alt="wave"
        />
        <nav className="z-10 mt-7 ml-10 mr-10 w-full flex flex-row sm:justify-between justify-center items-center">
          <a href="/">
            <img
              className="space-x-6"
              src={FullLogo}
              width={125}
              draggable={false}
              alt="bungfro branding"
            />
          </a>
          <ul className="sm:flex hidden space-x-6 font-bold">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/projects">Projects</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
  )
}

export default Header