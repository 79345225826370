/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client, { Project as ProjectType, toHtml, urlFor } from "./client";
import Header from "./Header";
import Footer from "./Footer";

const getSvgForKey = (key: string) => {
  switch (key) {
    case 'github':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61-.546-1.387-1.332-1.757-1.332-1.757-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.24 1.838 1.24 1.07 1.834 2.809 1.304 3.495.997.108-.775.418-1.305.76-1.605-2.665-.305-5.466-1.335-5.466-5.93 0-1.31.467-2.38 1.235-3.22-.124-.303-.535-1.524.116-3.176 0 0 1.008-.323 3.3 1.23.96-.267 1.98-.4 3-.404 1.02.004 2.04.137 3 .404 2.29-1.553 3.296-1.23 3.296-1.23.653 1.653.242 2.874.118 3.176.77.84 1.233 1.91 1.233 3.22 0 4.61-2.803 5.62-5.475 5.92.43.37.81 1.096.81 2.21 0 1.597-.015 2.887-.015 3.276 0 .32.218.694.825.576 4.765-1.59 8.2-6.085 8.2-11.386 0-6.627-5.373-12-12-12z"
          />
        </svg>
      );
    case 'twitter':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M24 4.557a9.94 9.94 0 0 1-2.828.775 4.932 4.932 0 0 0 2.165-2.724 9.885 9.885 0 0 1-3.127 1.195 4.92 4.92 0 0 0-8.384 4.482A13.944 13.944 0 0 1 1.671 3.149a4.926 4.926 0 0 0-.666 2.475c0 1.71.87 3.217 2.188 4.102a4.9 4.9 0 0 1-2.229-.616v.062a4.927 4.927 0 0 0 3.95 4.827 4.902 4.902 0 0 1-2.224.085 4.926 4.926 0 0 0 4.6 3.417A9.869 9.869 0 0 1 0 21.539a13.91 13.91 0 0 0 7.548 2.212c9.057 0 14.01-7.496 14.01-13.986 0-.21-.005-.42-.015-.63A10.012 10.012 0 0 0 24 4.557z"
          />
        </svg>
      );
    case 'roblox':
      return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M18.926 23.998L0 18.892L5.075.002L24 5.108ZM15.348 10.09l-5.282-1.453l-1.414 5.273l5.282 1.453z"
        />
      </svg>
      );
    case 'youtube':
      return (
        <svg
        className="w-5 h-5"
        viewBox="0 0 30 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3106 1.27838C27.5782 1.62071 28.5745 2.61957 28.9113 3.88573C29.524 6.18356 29.524 10.9809 29.524 10.9809C29.524 10.9809 29.524 15.7782 28.9113 18.076C28.5698 19.3469 27.5735 20.3457 26.3106 20.6834C24.0186 21.2977 14.8226 21.2977 14.8226 21.2977C14.8226 21.2977 5.63122 21.2977 3.33456 20.6834C2.06695 20.3411 1.07063 19.3422 0.73385 18.076C0.121094 15.7782 0.121094 10.9809 0.121094 10.9809C0.121094 10.9809 0.121094 6.18356 0.73385 3.88573C1.07531 2.61488 2.07162 1.61602 3.33456 1.27838C5.63122 0.664062 14.8226 0.664062 14.8226 0.664062C14.8226 0.664062 24.0186 0.664062 26.3106 1.27838ZM19.5234 10.9809L11.885 15.403V6.55872L19.5234 10.9809Z"
          fill="currentColor"
        />
      </svg>
      );
    default:
      return null
  }
};


function Project() {
  const { slug } = useParams<{ slug: string }>();
  const [project, setProject] = useState<ProjectType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const query = `*[_type == "project" && slug.current == $slug][0] {
        title,
    body,
    image,
    slug,
    links,
    publishedAt
      }`;

  useEffect(() => {
    if (slug) {
      client
        .fetch(query, { slug })
        .then((data: any) => {
          setProject(data);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error("Error fetching project:", error);
          setLoading(false);
        });
    }
  }, [slug]);

  if (loading) {
    return <p>Loading project...</p>;
  }

  if (!project) {
    return <p>Project not found.</p>;
  }
  console.log(project);
  return (
    <div className="">
      <Header />
      <section className="sm:py-12 py-16 mt-60 ">
        <div className="sm:px-6 px-4 max-w-[80rem] mx-auto">
          <div className="text-center max-w-[36rem] mx-auto">
            <h1 className="sm:text-5xl text-newBlack font-bold text-4xl">
              {project.title}
            </h1>
            <div className="justify-center items-center flex mt-8">
              <p className="text-zinc-500 font-semibold text-base">
                {new Date(project.publishedAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
          </div>

          <div className="sm:mt-12 mt-8 flex items-center justify-center">
            <img
              className="relative object-cover w-1/4 h-1/4 self-center rounded-md"
              src={urlFor(project.image).toString()}
              alt=""
            />
          </div>

          <div className="sm:mt-12 gap-y-8 grid-cols-1 grid mt-8">
            <div className="">
            <ul className="flex gap-3">
  {project &&
    Object.entries(project.links).map(([key, val]) => {
      return (
        val && getSvgForKey(key) && (
          <li key={key}>
            <a
              href={val}
              target="_blank"
              rel="noreferrer"
              className="transition-all duration-200 text-newBlack border border-zinc-200 rounded-full justify-center items-center w-10 h-10 inline-flex hover:text-white hover:bg-newBlack focus:ring-newBlack focus:ring focus:ring-offset-2"
            >
              <span className="absolute w-[1px] h-[1px] p-0 -m-[1px] overflow-hidden whitespace-nowrap border-0">
                {key} Preview
              </span>
              {getSvgForKey(key)}
            </a>
          </li>
        )
      );
    })}
</ul>

            </div>
            <article
              className="max-w-none"
              dangerouslySetInnerHTML={{ __html: toHtml(project.body) }}
            ></article>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Project;
