import { createClient } from "@sanity/client";
import ImageUrlBuilder from "@sanity/image-url";
// @ts-expect-error
import blocksToHtml from "@sanity/block-content-to-html"

const client = createClient({
  projectId: "yud7j69x",
  dataset: "production",
  apiVersion: "2024-09-30",
  useCdn: false,
});

const builder = ImageUrlBuilder(client);

export interface Project {
    title: string;
    image: string;
    slug: {
      current: string
    };
    body: any[];
    links: object;
    publishedAt: string;
  }
  

export const urlFor = (source: string) => builder.image(source);

export const toHtml = (body: any) => blocksToHtml({
    blocks: body
})

export function toPlainText(blocks: any[] = []) {
    return blocks
      .map(block => {
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        return block.children.map((child: any) => child.text).join('')
      })
      .join('\n\n')
  }

export default client as unknown as {
  fetch: <T>(query: string, other?: any) => any;
};
