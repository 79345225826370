import React, { useEffect, useState } from "react";
import Header from "./Header";
import client, { Project, toPlainText, urlFor } from "./client";
import Footer from "./Footer";

function Projects() {
  const query = `*[_type == "project"] | order(publishedAt desc) {
        title,
        body,
        image,
        slug,
        previewLink,
        publishedAt
      }`;
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    client
      .fetch<Project[]>(query)
      .then((data: Project[]) => {
        setProjects(data);
      })
      .catch((error: any) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  return (
    <div className="">
      <Header />
      <div className="grid sm:grid-cols-3 grid-cols-1 place-items-center pb-32 sm:pt-72 pt-56 gap-y-10">
            {projects &&
              projects.map((project, index) => (
                <div
                  key={index}
                  className="relative flex items-center flex-col my-6 h-full max-w-[90%] bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)] rounded-lg w-96"
                >
                  <div className="relative m-2.5 overflow-hidden text-white rounded-md">
                    <img
                      src={urlFor(project.image).toString()}
                      alt="card-image"
                    />
                  </div>
                  <div className="p-4">
                    <h6 className="mb-2 text-white text-xl font-semibold">
                      {project.title}
                    </h6>
                    <p className="text-white leading-normal font-light">
                    {toPlainText(project.body).length > 177
                ? toPlainText(project.body).substring(0, 177) + '...'
                : toPlainText(project.body)}
                    </p>
                  </div>
                  <div className="px-4 pb-4 pt-0 mt-2">
                    <button
                      className="rounded-md bg-[#003153] py-2 px-4 text-center text-white"
                      onClick={() => (window.location.href = `/projects/${project.slug.current}`)}
                      type="button"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <Footer />
    </div>
  );
}

export default Projects;
