import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

// Images
import BgImage from "./images/bgimage.png";
import FullLogo from "./images/FullLogo.png";
import SingleLogo from "./images/SingleLogo.png";
import MainWave from "./images/mainwave.png";
import client, { Project, toPlainText, urlFor } from "./client";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  const query = `*[_type == "project"] | order(publishedAt desc)[0...3] {
    title,
    body,
    image,
    slug,
    previewLink,
    publishedAt
  }`;
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    client
      .fetch<Project[]>(query)
      .then((data: Project[]) => {
        setProjects(data);
      })
      .catch((error: any) => {
        console.error("Error fetching projects:", error);
      });
  }, []);

  return (
    <div className="overflow-x-hidden bg-[#479CEB]">
      {/* Navigation */}

      <Header />
     
      <div
        className="absolute inset-0 top-12 bg-cover bg-center filter blur-[5px] sm:h-[1000px] h-[1200px] z-0"
        style={{ backgroundImage: `url('${BgImage}')` }}
      ></div>
      <div className="z-10 flex flex-col items-center justify-center h-[full] mt-80">
        <img
          className="pb-10 z-10"
          src={SingleLogo}
          width={100}
          draggable={false}
          alt="bungfro logo"
        />
        <div className="bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)] w-[97%] sm:h-[185px] h-96 rounded-3xl grid sm:grid-cols-3 sm:gap-0 grid-cols-1 grid-rows-1 grid-flow-row py-5 mb-10 max-w-[1140px] z-10">
          <div className="flex flex-col justify-center items-cente gap-5">
            <div className="max-w-full text-white text-6xl font-bold !text-center">
              9+
            </div>
            <h2 className="text-white text-xl font-normal !text-center">
              Years Experience
            </h2>
          </div>
          <div className="flex flex-col justify-center items-cente gap-5">
            <div className="max-w-full text-white text-6xl font-bold !text-center">
              126+
            </div>
            <h2 className="text-white text-xl font-normal !text-center">
              Commissions Completed
            </h2>
          </div>
          <div className="flex flex-col justify-center items-cente gap-5">
            <div className="max-w-full text-white text-6xl font-bold !text-center">
              99%
            </div>
            <h2 className="text-white text-xl font-normal !text-center">
              Positive Feedback
            </h2>
          </div>
        </div>
        <div
          className="bg-cover bg-center sm:h-[1305px] h-[2800px] w-full overflow-hidden flex flex-col justify-start pt-52 items-center z-10"
          style={{ backgroundImage: `url('${MainWave}')` }}
        >
          <h1 className="text-white font-bold text-5xl text-center mb-10">
            Featured Projects
          </h1>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-y-5 place-items-center">
            {projects &&
              projects.map((project, index) => (
                <div
                  key={index}
                  className="relative flex items-center flex-col my-6 h-full max-w-[90%] bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)] rounded-lg w-96"
                >
                  <div className="relative m-2.5 overflow-hidden text-white rounded-md">
                    <img
                      src={urlFor(project.image).toString()}
                      alt="card-image"
                    />
                  </div>
                  <div className="p-4">
                    <h6 className="mb-2 text-white text-xl font-semibold">
                      {project.title}
                    </h6>
                    <p className="text-white leading-normal font-light">
                    {toPlainText(project.body).length > 177
                ? toPlainText(project.body).substring(0, 177) + '...'
                : toPlainText(project.body)}
                    </p>
                  </div>
                  <div className="px-4 pb-4 pt-0 mt-2">
                    <button
                      className="rounded-md bg-[#003153] py-2 px-4 text-center text-white"
                      onClick={() => (window.location.href = `/projects/${project.slug.current}`)}
                      type="button"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <button
            className="rounded-md bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 py-2 px-4 mt-10 text-center text-white"
            onClick={() => (window.location.href = "/projects")}
            type="button"
          >
            View All
          </button>
        </div>
      </div>

      <div className="z-10 overflow-hidden  flex flex-col justify-start items-center">
        <h1 className="font-bold text-white text-5xl text-center flex justify-center items-center gap-6">
          About
          <img
            className="space-x-6"
            width={180}
            src={FullLogo}
            draggable={false}
            alt="bungfro branding"
          />
        </h1>
        <div className="relative w-[97%] h-[300px] rounded-3xl mt-10 mb-10 max-w-[1140px] z-10 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)]">
          <div className="bg-[url('./images/bgimage.png')] bg-repeat bg-cover inset-0 absolute rounded-3xl filter"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 rounded-3xl"></div>
          <div className="flex justify-center items-center h-full">
            <h1 className="relative z-10 text-white p-5 sm:text-3xl text-1xl font-semibold text-center">
              Hey, I’m Bungfro! I’ve been coding for a while now, and I’ve
              gotten pretty good at it. I mainly work with JavaScript, Lua, and
              Luau, and I know my way around them pretty well. I’ve built all
              kinds of cool things using these languages and love seeing my
              ideas come to life in the apps I make. It's fun to take on new
              challenges and push my skills further!
            </h1>
          </div>
        </div>
      </div>

    <Footer />
    </div>
  );
}

export default App;
