import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

import ColonizPFP from "./images/colonizpfp.png";

interface FormData {
  discordUsername: string;
  emailAddress: string;
  message: string;
}

function Contact() {
  const [formData, setFormData] = useState<FormData>({
    discordUsername: "",
    emailAddress: "",
    message: "",
  });

  const [status, setStatus] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus(null);

    try {
      const response = await fetch("/api/send-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus("Message sent successfully!");
        setFormData({ discordUsername: "", emailAddress: "", message: "" }); // Reset form
      } else {
        setStatus(data.error || "Failed to send message.");
      }
    } catch (error) {
      setStatus("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <section className="3xl:mt-[35rem] mt-60 pb-20">
        <div className="lg:px-8 sm:px-6 px-4 max-w-[80rem] mx-auto">
          <div className="lg:gap-x-16 lg:grid-cols-2 gap-y-12 grid-cols-1 grid">
            <div className="self-stretch justify-between flex-col flex">
              <div className="flex-1 pb-16">
                <h2 className="lg:text-5xl sm:text-4xl text-newBlack tracking-tight font-semibold text-3xl">
                  Get in touch 👋
                </h2>
                <p className="lg:leading-8 lg:text-lg lg:mt-6 text-slate-600 leading-7 font-normal text-base mt-4">
                  Looking for help with your Roblox game? Reach out, and I can
                  create custom scripts to make your project exactly how you
                  want it.
                </p>
              </div>
              <div className="lg:mt-0 mt-10 shadow-[0px_0px_10px_0px_rgba(255,255,255,0.5)] rounded-3xl overflow-hidden -rotate-3 relative">
                <div className="bg-[url('./images/bgimage.png')] bg-repeat bg-cover inset-0 absolute rounded-3xl filter"></div>
                <div className="absolute inset-0 bg-gradient-to-b from-[#02ACFF] to-[#035DD7] opacity-90 rounded-3xl"></div>
                <div className="sm:py-9 sm:px-8 p-6 relative">
                  <div className="items-center flex">
                    <svg
                      className="text-yellow-300 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <svg
                      className="text-yellow-300 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <svg
                      className="text-yellow-300 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <svg
                      className="text-yellow-300 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                    <svg
                      className="text-yellow-300 w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </div>
                  <blockquote className="mt-8">
                    <p className="text-white font-normal text-xl">
                      "I’ve been running genres on the ROBLOX platform since
                      2012… the commitment and dedication of this individual is
                      like no other developer I’ve ever seen. I have hired more
                      developers than I can count on two hands, Bungfro by far
                      has taken the cake for his in depth knowledge of LUA. The
                      biggest win to me is the dedication and commitment that
                      I’ve seen come from Bungfro, not once or twice, but almost
                      on a DAILY basis."
                    </p>
                  </blockquote>
                  <div className="items-center flex mt-6">
                    <img
                      className="object-cover rounded-full shrink-0 w-11 h-11"
                      src={ColonizPFP}
                      alt="coloniz profile pic"
                    />
                    <div className="ml-4">
                      <p className="text-white font-semibold text-base">
                        Coloniz
                      </p>
                      <p className="text-slate-300 font-normal text-sm">
                        Owner, British Frontier
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-xl bg-white rounded-2xl">
              <div className="sm:p-10 p-6">
                <h3 className="sm:text-3xl text-newBlack tracking-tight font-semibold text-2xl">
                  Send me a message
                </h3>
                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                  <div>
                    <label htmlFor="discord" className="hide">
                      Discord username
                    </label>
                    <div>
                      <input
                        type="text"
                        name="discordUsername"
                        placeholder="Discord username"
                        value={formData.discordUsername}
                        onChange={handleChange}
                        required
                        className="text-newBlack text-base py-4 bg-white border-b-2 w-full block focus:ring-0 placeholder:text-slate-600 outline-none focus:border-[#02ACFF]"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="hide">
                      Email address
                    </label>
                    <div>
                      <input
                        type="email"
                        name="emailAddress"
                        placeholder="Email address"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        required
                        className="text-newBlack text-base py-4 bg-white border-b-2 w-full block focus:ring-0 placeholder:text-slate-600 outline-none focus:border-[#02ACFF]"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="message" className="hide">
                      Message
                    </label>
                    <div>
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="text-newBlack text-base py-4 bg-white border-b-2 resize-y w-full block focus:ring-0 placeholder:text-slate-600 outline-none focus:border-[#02ACFF]"
                      />
                    </div>
                  </div>
                  {status && status.includes("successfully") ?  <p className="text-green-500">{status}</p> : <p className="text-red-600"></p>}
                  <button
                    type="submit"
                    className="duration-200 transition-all text-white font-medium text-base py-4 px-12 bg-[#02ACFF] border-transparent border rounded-xl justify-center items-center w-full inline-flex"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
